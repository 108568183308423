import { ActionType, nanoid, ProColumns } from "@ant-design/pro-components";
import { Badge, Button, Checkbox, CheckboxProps, DatePicker, Form, Input, InputRef, Select, Space, Tooltip } from "antd";
import { CheckStatus, CloseStatus, Filter, Print, Trash } from "base/ui/components/Icons";
import CustomProTable, { tablePagination } from "base/ui/components/CustomTable";
import { DATE_FORMAT_DEFAULT, PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from "utils/constants";
import { SearchBox, SearchForm, SearchItem } from "pages/Components/SearchBox";
import { checkPermissionWithId, useCheckPermission } from "utils/permissionHelper";
import { cloneDeep, reverse, sortBy, sortedUniqBy, uniq } from "lodash";
import { commonPresenter, commonStore, purchaseOrderPresenter, purchaseOrderStore } from "stores/root_store";
import { getFullName, getRole, getRoleName, getUserName, getUserSession, getsaleOrgName } from "utils/localStorageHelper";
import { useCallback, useEffect, useRef, useState } from "react";

import BoxContainer from "base/ui/components/BoxContainer";
import { CommonModel } from "services/Main/common_services";
import CustomButton from 'base/ui/components/Button';
import CustomButtonIcon from "base/ui/components/Button/buttonIcon";
import DeleteDeliveryConfirm from "pages/purchaseOrder/ListPO/deleteDeliveryConfirm";
import { DeliveryRegistrationModel } from "models/DeliveryRegistration";
import { FaFilter } from "react-icons/fa";
import { FilterDropdownProps } from "antd/es/table/interface";
import { HiSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import PageWrapper from "pages/Components/PageWrapper"
import { ROUTES } from "base/routes/routes";
import SaveReportForm from "pages/Components/reportForm";
import { SearchDeliveryRegistration } from "services/Main/purchaseOrder_services";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { debounce } from "utils/debounce";
import moment from "moment";
import { observer } from "mobx-react"
import { removeEmptyValues } from "utils/transformHelper";
import { useResizableColumns } from "utils/reportHelper";
import { vendorList } from "pages/rawData/Permission/model";

const initialSearch = {
    paging: {
        pageSize: PAGE_SIZE_DEFAULT,
        pageIndex: 1,
        orderBy: undefined,
        orderByDesc: undefined,
    },
    documentDateFrom: dayjs().format('YYYY-MM-DD'),
    documentDateTo: dayjs().format('YYYY-MM-DD'),
    ...(getRole() === "NCC" && { vendorNumber: getUserName() }),
    // documentDateFrom: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    // documentDateTo: dayjs().format('YYYY-MM-DD')
}

export function createVehicleRegistration() {
    return observer(() => {
        document.title = 'Đăng ký giao hàng - ' + SYSTEM_TITLE;
        // phân quyền
        useCheckPermission("INDEX")
        const isCancel = checkPermissionWithId("CANCEL")
        // disable nhập ngày khi chọn khác "Custom"
        const [disableDatePicker, setDisableDatePicker] = useState(false)
        const [searchForm] = Form.useForm()
        // show filter form
        // mở drawer search
        const [openDrawer, setOpenDrawer] = useState(false)
        // const [vendorNumber, setVendorNumber] = useState('');

        //=== COLUMNS TABLE =================================
        const [dataPaging, setDataPaging] = useState<SearchDeliveryRegistration>(initialSearch);
        // data search danh sách đăng ký giao hàng 
        const [dataTable, setDataTable] = useState<DeliveryRegistrationModel[]>([]);
        const [listVendor, setListVendor] = useState<CommonModel[]>([])
        // mở modal delete
        const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
        // data delete 
        const [dataDelete, setDataDelete] = useState<DeliveryRegistrationModel>()
        const [deleteLoading, setDeleteLoading] = useState(false)
        const [tableLoading, setTableLoading] = useState(true)

        const searchInput = useRef<InputRef>(null);
        const [searchText, setSearchText] = useState<Record<string, string>>({});
        const [searchedColumn, setSearchedColumn] = useState('');
        const [tableKey, setTableKey] = useState(0)

        const handleSearch = (
            _selectedKeys: string[],
            confirm: FilterDropdownProps['confirm'],
            dataIndex: keyof DeliveryRegistrationModel,
        ) => {
            confirm();
            // setSearchText(selectedKeys);
            setSearchedColumn(dataIndex);
        };

        const handleReset = (clearFilters: () => void, confirm: FilterDropdownProps['confirm'], dataIndex: keyof DeliveryRegistrationModel) => {
            clearFilters();
            confirm()
            setSearchText(prev => ({ ...prev, [dataIndex]: '' }))
            // setSearchText([]);
        };

        const getColumnSearchProps = (dataIndex: keyof DeliveryRegistrationModel, type?: 'arrayString' | 'arrayDate' | 'date'): ProColumns<DeliveryRegistrationModel> => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInput}
                        placeholder={`Tìm kiếm`}
                        // value={selectedKeys.length < 2 ? selectedKeys[0] : undefined}
                        onChange={(e) => setSearchText(prev => ({ ...prev, [dataIndex]: e.target.value }))}
                        // onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <hr className="mb-2 mt-1" />
                    {(() => {
                        let arrString = [] as string[];
                        switch (type) {
                            case 'arrayString':
                                arrString = dataTable.flatMap(data => data[dataIndex] ? data[dataIndex] : ['-']) as string[];
                                break;
                            case 'arrayDate':
                                const arrDate = dataTable.flatMap(data => data[dataIndex] ? data[dataIndex] : ['-']) as string[];
                                const sorted = sortBy(arrDate, (date) => new Date(date).getTime());
                                arrString = reverse(sorted.map(data => data !== '-' ? dayjs(data).format('DD/MM/YYYY') : data))
                                break;
                            case "date":
                                const arrDates = dataTable.map(data => data[dataIndex] ? dayjs(data[dataIndex] as string).format('DD/MM/YYYY') : '-') as string[];
                                arrString = arrDates
                                break;
                            default:
                                arrString = dataTable.map(data => data[dataIndex] ? data[dataIndex] : '-') as any[];
                                break;
                        }

                        // loại bỏ các mã trùng
                        const uniqueArr = uniq(arrString);
                        const searchArr = uniqueArr.filter(i => searchText ? (i + '').toLowerCase().includes(searchText[dataIndex]?.toLowerCase() || '') : true)
                        const checkedAll = searchArr.length === selectedKeys.length
                        const indeterminate = selectedKeys.length > 0 && selectedKeys.length < searchArr.length;
                        const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
                            setSelectedKeys(e.target.checked ? searchArr as string[] : []);
                            // setSearchText(e.target.checked ? uniqueArr as string[] : [])
                        };

                        return (
                            <Space direction='vertical' className="max-h-[250px] overflow-y-auto w-full" >
                                <Checkbox checked={checkedAll}
                                    onChange={onCheckAllChange} indeterminate={indeterminate}>Tất cả</Checkbox>
                                <Checkbox.Group value={selectedKeys as string[]} onChange={(checkValues) => {
                                    setSelectedKeys(checkValues as string[])
                                    // setSearchText(checkValues as string[])
                                }}>
                                    <Space direction='vertical'>
                                        {
                                            searchArr.map((item, index) => (
                                                <Checkbox key={nanoid()} value={item}>
                                                    {item}
                                                </Checkbox>
                                            ))
                                        }
                                    </Space>
                                </Checkbox.Group>
                            </Space>
                        )
                    })()}

                    <hr className="mb-2 mt-1" />
                    <Space>
                        <Button
                            type="primary"
                            htmlType="button"
                            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <FaFilter style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            onFilter: (value, record) => {
                if (type === 'arrayString') {
                    return ((record[dataIndex] || []) as string[]).some(v => v.toString().toLowerCase() === (value as string).toLowerCase())
                }
                if (type === 'arrayDate') {
                    return ((record[dataIndex] || []) as string[]).some(v => dayjs(v).format("DD/MM/YYYY") === (value as string))
                }
                if (type === 'date') {
                    return (record[dataIndex] ? dayjs(record[dataIndex] as string).format("DD/MM/YYYY") : '-').includes(value as string)
                }

                return (record[dataIndex] || '-')
                    .toString()
                    .toLowerCase() === (value as string).toLowerCase()

            },
            onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
        });

        const defaultDataTable: ProColumns<DeliveryRegistrationModel>[] = [
            {
                title: 'In phiếu',
                dataIndex: 'print',
                key: 'print',
                width: 70,
                fixed: 'left',
                align: 'center',
                render(text, record) {
                    return <div className="flex w-full justify-center items-center group cursor-pointer">
                        <Link to={"/Print/" + record.deliveryRegistrationId} target="_blank">
                            <Print className="fill-blue-800" width="13" />
                        </Link>
                    </div>
                }
            },
            {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',
                fixed: 'left',
                width: 50,
                align: 'center',
            },

            {
                title: 'Mã phiếu đăng ký',
                dataIndex: 'deliveryRegistrationCode',
                key: 'deliveryRegistrationCode',
                fixed: 'left',
                width: 150,
                render(text, record) {
                    return <div className="flex gap-2 items-center group cursor-pointer">
                        <Link to={ROUTES.PURCHASE_ORDER.LIST_PO.EDIT_VOTE.LINK + `/${record.deliveryRegistrationId}`} className="group-hover:text-blue-600">{text}</Link>
                    </div>
                },
                ...getColumnSearchProps('deliveryRegistrationCode')
            },
            {
                title: 'Mã nhà cung cấp',
                dataIndex: 'vendorNumber',
                key: 'vendorNumber',
                width: 150,
                fixed: 'left',
                ...getColumnSearchProps('vendorNumber')
            },
            {
                title: 'Tên nhà cung cấp',
                dataIndex: 'vendorName',
                key: 'vendorName',
                width: 500,
                // render: (_, record) => {
                //     return record.vendorName
                // }
                ...getColumnSearchProps('vendorName')
            },

            {
                title: "Tình trạng phiếu",
                dataIndex: 'taskStatusName',
                width: 140,
                // align: 'center',
                key: 'taskStatusName',
                render: (text, record) => {
                    return <div className="w-full !text-[10px] !text-center font-medium !py-0.5 border rounded-sm"
                        style={{
                            borderColor: record.color + '80',
                            color: record.color,
                            backgroundColor: record.color + '1a'
                        }}
                    >{text}</div>
                    // return <span className="flex gap-2">
                    //     <Badge color={record.color}></Badge>
                    //     <p style={{
                    //         color: record.color,
                    //         fontWeight: 'bold'
                    //     }}>{text}</p>
                    // </span>
                },
                ...getColumnSearchProps('taskStatusName')
            },
            {
                title: 'Ngày giao hàng',
                dataIndex: 'deliveredTimes',
                key: 'deliveredTimes',
                width: 150,
                align: 'center',
                onCell: () => ({ className: `!p-0 !pt-0 divide-y` }),
                render: (_, record) => {
                    return record?.deliveredTimes.length ? record.deliveredTimes.map((time, index) => (
                        <div key={index} className={`w-full py-1 px-2`}>
                            {moment(time).format("DD/MM/YYYY HH:mm:ss")}
                        </div>
                    )) : '-'
                },
                ...getColumnSearchProps('deliveredTimes', 'arrayDate')
            },
            {
                title: 'Mặt hàng',
                dataIndex: 'listProduct',
                key: 'listProduct',
                width: 350,
                onCell: () => ({ className: `!p-0 !pt-0 divide-y` }),
                render: (_, record) => {
                    return record?.listProduct.length ? record.listProduct.map((prd, index) => (
                        <div key={index} className={`w-full py-1 px-2 truncate`}>
                            {prd}
                        </div>
                    )) : '-'
                },
                ...getColumnSearchProps('listProduct', 'arrayString')
            },
            {
                title: 'Tài xế',
                dataIndex: 'driverName',
                key: 'driverName',
                width: 250,
                ...getColumnSearchProps('driverName')
            },
            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                key: 'phone',
                width: 130,
                ...getColumnSearchProps('phone')
            },
            {
                title: 'Số xe',
                dataIndex: 'licensePlate',
                key: 'licensePlate',
                width: 150,
                ...getColumnSearchProps('licensePlate')
            },
            {
                title: 'Thời gian thực tế chuyển trạng thái',
                dataIndex: 'lastEditTime',
                key: 'lastEditTime',
                width: 200,
                align: 'center',
                fieldProps: {
                    format: 'DD/MM/YYYY HH:mm:ss',
                },
                valueType: 'dateTime',
                ...getColumnSearchProps('lastEditTime', 'date')
            },
            {
                title: "Trạng thái hoạt động",
                dataIndex: 'actived',
                width: 140,
                align: 'center',
                key: 'actived',
                render: (text, record) => {
                    if (record.actived === true) {
                        return <CheckStatus className="!fill-[#389e0d] mx-auto" width="15" />
                    } else if (record.actived === false) {
                        return <CloseStatus width="15" className="!fill-red-500 mx-auto" />
                    }
                    return text
                }
            },

            {
                title: "Chức năng",
                key: 'function',
                align: 'center',
                width: 90,
                hideInTable: !isCancel,
                hideInSetting: !isCancel,
                render: (_, record) => {
                    return (
                        <div className="flex justify-center">
                            {record.isShowCancelButton && (<Tooltip title={"Hủy phiếu"}>
                                <CustomButtonIcon
                                    color="#FC5C5E"
                                    onClick={() => {
                                        // cancelDeliveryRegistration(record)
                                        setDataDelete(record)
                                        setModalDeleteOpen(true)
                                    }}
                                >
                                    <Trash className="!fill-[#FC5C5E]" width="12" height="12" />
                                </CustomButtonIcon>
                            </Tooltip>)}
                        </div>
                    )
                }
            },

        ]

        const [columns, setColumns] = useState<ProColumns<DeliveryRegistrationModel>[]>(defaultDataTable)

        const getDataTable = async (values: SearchDeliveryRegistration) => {
            setTableLoading(true)
            await purchaseOrderPresenter.getListDeliveryRegistration(purchaseOrderStore, { ...values, paging: { ...values.paging, pageSize: 1000000000 } })
            setTableLoading(false)
            if (purchaseOrderStore.deliveryRegistrationRes?.isSuccess) {
                setDataTable(purchaseOrderStore.deliveryRegistrationRes?.data)
                return purchaseOrderStore.deliveryRegistrationRes?.data
            }
            return []
        }

        // lấy common date 
        // kiểm tra đã có trong store chưa nếu chưa có thì call api lấy ds
        const getListCommom = async () => {
            if (!commonStore.listCommonDate.length) {
                await commonPresenter.getListCommonDate(commonStore)
            }
        }
        // Dropdown nhà cung cấp
        const getDropdownVendor = async (keyword?: string) => {
            await commonPresenter.getDropdownVendor(commonStore, { keyword: keyword, takeNumber: 20 })
            if (commonStore.commonRespone.isSuccess && commonStore.commonRespone.data.length) {
                setListVendor(commonStore.commonRespone.data)
            }
        }
        const searchVendor = useCallback(debounce(getDropdownVendor, 300), [])

        // set lại colum khi data table thay đổi 
        // cập nhật lại filter
        useEffect(() => {
            setColumns(prev => {
                const col = defaultDataTable.map((column, index) => ({ ...column, width: prev[index].width }))
                return col
            })
        }, [dataTable, searchText]);

        useEffect(() => {
            console.log(listVendor)
            getDataTable(dataPaging)
            getDropdownVendor()
            getListCommom()
            tableRef.current?.reload()
            let user = getUserSession();
            console.log({ user })
            // let role = getRole();
            // if (role === 'NCC') {
            //     let vendorNumber = getUserSession();
            //     const vendorName = getFullName();
            //     setVendorNumber(vendorNumber.userName + " | " + vendorName);
            // }

        }, []);


        const vendor_number = getUserName();
        const vendor_name = getFullName();
        const role = getRole();

        // If the role is "NCC", set the initial value for the vendorNumber
        const initialValues = {
            vendorNumber: role === 'NCC' ? vendor_number : '',
        };

        useEffect(() => {
            if (role === 'NCC' && vendor_number) {
                // Check if the vendorNumber exists in the options
                const valueExists = listVendor.some(option => option.key === vendor_number);

                if (!valueExists && vendor_name) {
                    // Add the vendorNumber and its label to the options array
                    setListVendor(prevOptions => [
                        ...prevOptions,
                        { key: vendor_number, value: vendor_number + " | " + vendor_name }
                    ]);
                }

                // Set the initial value for the form
                searchForm.setFieldsValue({ vendorNumber: vendor_number });
            }
        }, [vendor_number, vendor_name, role, listVendor, searchForm]);

        // == CẤU HÌNH TABLE =================================================================
        const tableRef = useRef<ActionType>();
        const constDataName = 'DanhSachPO';
        const {
            isResizablePresent,
            setIsResizablePresent,
            resizableColumnSelect,
            columnsState,
            handleColumnsStateChange,
            templateReportModalRef,
            userReports,
            selectedReportId,
            templateReportModalProps,
            handleResize,
        } = useResizableColumns<DeliveryRegistrationModel>(columns, setColumns, constDataName);

        // const initialValuesOld = {
        //     vendorNumber: getRole() === 'NCC' ? vendorNumber : '',
        // }

        return (
            <PageWrapper breadcrumb={[{
                text: "Danh sách phiếu đăng ký giao hàng"
            }]}
                extras={
                    <CustomButton
                        onClick={() => setOpenDrawer(true)}
                        type="primary" color="#EFF4F9"
                        className="!px-0 w-8"
                    >
                        <Filter className="!fill-primary" />
                    </CustomButton>
                }
            >
                {/* Table */}
                <BoxContainer >
                    <CustomProTable<DeliveryRegistrationModel>
                        loading={tableLoading}
                        bordered
                        columns={columns}
                        key={tableKey}
                        dataSource={dataTable}
                        actionRef={tableRef}
                        handleResize={handleResize}
                        onRow={(record, index) => {

                            let color = ''; // Default text color


                            // Nếu isCreatedBySystem là true, đổi màu chữ thành màu đỏ
                            // @ts-ignore
                            if (record.isCreateBySystem) {
                                color = '#dc2626';
                            }

                            return {
                                style: {
                                    color: color,
                                },
                            };
                        }}
                        isResizablePresent={isResizablePresent}
                        resizableColumnSelect={resizableColumnSelect}
                        columnsState={{
                            value: Object.keys(columnsState).length > 0 ? columnsState : undefined,
                            onChange: (newColumnsState) => {
                                handleColumnsStateChange(newColumnsState);
                            },
                        }}
                        toolBarRender={() => [
                            // nút lưu mẫu bc
                            <SaveReportForm
                                setIsResizablePresent={setIsResizablePresent}
                                templateReportModalRef={templateReportModalRef}
                                userReports={userReports}
                                selectedReportId={selectedReportId}
                                templateReportModalProps={templateReportModalProps}
                            />,
                        ]}
                        rowKey="stt"
                        pagination={{
                            ...tablePagination(dataPaging, setDataPaging),
                            position: ['topRight'],
                            // total: purchaseOrderStore.deliveryRegistrationRes?.paging?.recordsTotal,
                        }}
                    // request={async (params = {}, sort, filter) => {
                    //     const data = await getDataTable({
                    //         ...dataPaging,
                    //         paging: {
                    //             ...dataPaging.paging,
                    //             orderBy:
                    //                 Object.values(sort)[0] === "ascend"
                    //                     ? `${Object.keys(sort)[0].split(",").pop()}`
                    //                     : undefined,
                    //             orderByDesc:
                    //                 Object.values(sort)[0] === "descend"
                    //                     ? `${Object.keys(sort)[0].split(",").pop()}`
                    //                     : undefined,
                    //         },
                    //     });
                    //     if (Object.values(sort).length) {

                    //         setDataPaging({
                    //             ...dataPaging,
                    //             paging: {
                    //                 ...dataPaging.paging,
                    //                 orderBy:
                    //                     Object.values(sort)[0] === "ascend"
                    //                         ? `${Object.keys(sort)[0].split(",").pop()}`
                    //                         : undefined,
                    //                 orderByDesc:
                    //                     Object.values(sort)[0] === "descend"
                    //                         ? `${Object.keys(sort)[0].split(",").pop()}`
                    //                         : undefined,
                    //             },
                    //         });
                    //     }
                    //     return {
                    //         data: data,
                    //         success: purchaseOrderStore.deliveryRegistrationRes?.isSuccess
                    //     };

                    // }}
                    />
                </BoxContainer>
                <SearchBox
                    onClose={() => { setOpenDrawer(false) }}
                    className="lg:!w-[50%]"
                    open={openDrawer}
                    footer={
                        <CustomButton
                            htmlType="submit"
                            className="my-auto ml-auto"
                            type="primary"
                            // loading={isLoading}
                            icon={<HiSearch />}
                            onClick={() => {
                                searchForm.submit();
                            }}
                        >
                            Tìm kiếm
                        </CustomButton>
                    }
                >
                    <SearchForm
                        form={searchForm}
                        initialValues={initialValues}
                        onFinish={(values) => {
                            const valueClone = cloneDeep(values)
                            // convert lại from date to date
                            const documentDateFrom = valueClone?.documentDateFrom ? valueClone.documentDateFrom?.format("YYYY-MM-DD") : undefined
                            const documentDateTo = valueClone?.documentDateTo ? valueClone.documentDateTo?.format("YYYY-MM-DD") : undefined
                            Object.assign(valueClone, { documentDateFrom, documentDateTo })
                            // bỏ các giá trị rỗng null và trim giá trị
                            removeEmptyValues(valueClone)
                            const dataSearch: SearchDeliveryRegistration = { paging: { ...dataPaging.paging, pageIndex: 1 }, ...valueClone }
                            setDataPaging(dataSearch)
                            setOpenDrawer(false)
                            setTableKey(prev => prev + 1)
                            setSearchText({})
                            // tableRef.current?.reload()
                            getDataTable(dataSearch)
                        }}
                    >
                        {/* Mã nhà cung cấp */}
                        <SearchItem name={'vendorNumber'} label={'Nhà cung cấp'} >
                            <Select
                                allowClear
                                options={listVendor}
                                fieldNames={{ label: "value", value: 'key' }}
                                filterOption={false}
                                popupMatchSelectWidth={false}
                                disabled={role === 'NCC' ? true : false}
                                placeholder='Vui lòng chọn'
                                showSearch onSearch={(values) => searchVendor(values || undefined)}
                            />
                        </SearchItem>
                        {/* Mã phiếu đăng ký */}
                        <SearchItem name={'deliveryRegistrationCode'} label={'Mã phiếu đăng ký'}>
                            <Input placeholder="Nhập mã phiếu" />
                        </SearchItem>
                        {/* Mã hàng hóa */}
                        <SearchItem name={'productCode'} label={'Mã hàng hóa'}>
                            <Input placeholder="Nhập mã hàng hóa" />
                        </SearchItem>
                        {/* Tên hàng hóa */}
                        <SearchItem name={'productName'} label={'Tên hàng hóa'}>
                            <Input placeholder="Nhập tên hàng hóa" />
                        </SearchItem>
                        {/* Tên tài xế */}
                        <SearchItem name={'driverName'} label={'Tên tài xế'}>
                            <Input placeholder="Nhập tài xế" />
                        </SearchItem>

                        {/* Ngày giao hàng */}
                        <SearchItem initialValue={'Today'} name={'common'} label={'Ngày giao hàng'} start>
                            <Select options={commonStore.listCommonDate} fieldNames={{ label: 'value', value: 'key' }} onChange={async (value) => {
                                if (value && value !== "Custom") {
                                    setDisableDatePicker(true)
                                    await commonPresenter.getDateByCommonDate(commonStore, value)
                                    if (commonStore.commonDate.fromDate) {
                                        searchForm.setFieldsValue({ documentDateFrom: dayjs(commonStore.commonDate.fromDate, "YYYY-MM-DD"), documentDateTo: dayjs(commonStore.commonDate.toDate, "YYYY-MM-DD") })
                                    }
                                } else {
                                    setDisableDatePicker(false)
                                    searchForm.setFieldsValue({ documentDateFrom: undefined, documentDateTo: undefined })
                                }
                            }} />
                        </SearchItem>
                        {/* Từ ngày */}
                        <SearchItem
                            initialValue={dayjs()}
                            name={'documentDateFrom'} label={'Từ ngày'}
                            start
                            dependencies={['documentDateTo']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value || getFieldValue('documentDateTo')) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(new Error('Chưa nhập giá trị "Từ ngày".'));;
                                    },
                                })
                            ]}
                        >
                            <DatePicker changeOnBlur disabled={disableDatePicker} format={DATE_FORMAT_DEFAULT} className="w-full" />
                        </SearchItem>
                        {/* Đến ngày */}
                        <SearchItem
                            initialValue={dayjs()}
                            name={'documentDateTo'}
                            label={'Đến ngày'}
                            dependencies={['documentDateFrom']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value || getFieldValue('documentDateFrom')) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Chưa nhập giá trị "Đến ngày".'));
                                    },
                                })
                            ]}
                        >
                            <DatePicker changeOnBlur disabled={disableDatePicker} format={DATE_FORMAT_DEFAULT} className="w-full" />
                        </SearchItem>
                    </SearchForm>
                </SearchBox>

                <DeleteDeliveryConfirm
                    open={modalDeleteOpen}
                    onOpenChange={(open) => {
                        setModalDeleteOpen(open);
                        setDataDelete(undefined)
                    }}
                    loading={deleteLoading}
                    dataDelete={dataDelete}
                    onFormSubmit={(value) => {
                        value.then(async (data) => {
                            setDeleteLoading(true);
                            const result = await purchaseOrderPresenter.cancelDeliveryRegistration(data)
                            setDeleteLoading(false);
                            if (result) {
                                setDataDelete(undefined)
                                setModalDeleteOpen(false)
                                tableRef.current?.reload()
                            }
                        }).catch((error) => {
                        })
                    }}
                />
            </PageWrapper>

        )
    })
}