import './style.css';
import './style.css'

import * as Icon from 'base/ui/components/Icons';

import { ActionType, ProColumns } from "@ant-design/pro-components";
import { BASE_API_URL, PAGE_SIZE_DEFAULT, SYSTEM_TITLE } from "utils/constants";
import { Button, Checkbox, CheckboxProps, Form, Input, InputRef, Modal, Space, Tooltip, Upload, message } from "antd";
import { CopyOutlined, ExclamationCircleFilled, SearchOutlined } from "@ant-design/icons";
import CustomProTable, { tablePagination } from "base/ui/components/CustomTable";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Filter, PencilEdit } from "base/ui/components/Icons";
import { FilterDropdownProps, FilterValue } from "antd/es/table/interface";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineNoteAlt, MdOutlinePostAdd } from "react-icons/md";
import { SearchBox, SearchForm, SearchItem } from "pages/Components/SearchBox";
import { checkPermissionWithId, useCheckPermission } from "utils/permissionHelper";
import { cloneDeep, differenceWith, map, omit, reverse, sortBy, sortedUniqBy, uniq, uniqBy } from "lodash";
import { notificationPresenter, notificationStore, purchaseOrderPresenter, purchaseOrderStore } from "stores/root_store";
import { useEffect, useRef, useState } from "react";

import BoxContainer from "base/ui/components/BoxContainer";
import CustomButton from 'base/ui/components/Button';
import CustomButtonIcon from "base/ui/components/Button/buttonIcon";
import { ExportButton } from "pages/Components/FunctionButton";
import { HiSearch } from "react-icons/hi";
import PageWrapper from "pages/Components/PageWrapper"
import { PurchaseOrderModel } from "models/PurchaseOrder";
import { ROUTES } from "base/routes/routes";
import SaveReportForm from "pages/Components/reportForm";
import { SearchModel } from "pages/model";
import { SearchPO } from "services/Main/purchaseOrder_services";
import SearchPOForm from "./SearchPOForm";
import { createCopyPurchaseOrder } from '../CreatePOCopy';
import dayjs from "dayjs";
import { formatNumber } from "utils/formatHelper";
import { getAccessToken, getFullName, getRole, getUserName } from "utils/localStorageHelper";
import { observer } from "mobx-react"
import { removeEmptyValues } from "utils/transformHelper";
import { useResizableColumns } from "utils/reportHelper";
import { useTranslation } from "react-i18next";

const initSearch = {
    paging: {
        pageSize: PAGE_SIZE_DEFAULT,
        pageIndex: 1,
    },
    // documentDateFrom: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    // documentDateTo: dayjs().format('YYYY-MM-DD')
    documentDateFrom: dayjs().format('YYYY-MM-DD'),
    documentDateTo: dayjs().format('YYYY-MM-DD'),
    actived: true,
    ...(getRole() === "NCC" && { vendorNumber: getUserName(), vendorName: getFullName() }),
}

export function createPurchaseOrder() {
    return observer(() => {
        document.title = 'Purchase Order - ' + SYSTEM_TITLE;
        useCheckPermission("INDEX")
        const isCreateVote = checkPermissionWithId('CREATE_VOTE')
        const isUpdateNote = checkPermissionWithId('EDIT_NOTE')
        const isExport = checkPermissionWithId('EXPORT')
        const isCreatePO = checkPermissionWithId('CREATE')
        const isUpdatePO = checkPermissionWithId('EDIT')
        const isDeletePO = checkPermissionWithId('DELETE')
        const isExportTemplate = checkPermissionWithId('EXPORT_TEMPLATE')
        const isImport = checkPermissionWithId('IMPORT')

        const navigate = useNavigate()

        const [searchForm] = Form.useForm()
        // form cập nhật ghi chú
        const [formUpdate] = Form.useForm()
        const [tableKey, setTableKey] = useState(0)
        // loading
        const [isLoading, setIsLoading] = useState(false)
        // mở drawer search
        const [openDrawer, setOpenDrawer] = useState(false)
        // modal update open
        const [openModalUpdate, setOpenModalUpdate] = useState(false)
        const [loadingTable, setLoadingTable] = useState(true)

        // Loading import và export templates
        const [loadingExport, setLoadingExport] = useState(false);
        const [loadingImport, setLoadingImport] = useState(false);

        const searchInput = useRef<InputRef>(null);
        const [searchText, setSearchText] = useState<Record<string, string>>({});
        const [searchedColumn, setSearchedColumn] = useState('');
        const handleSearch = (
            selectedKeys: string[],
            confirm: FilterDropdownProps['confirm'],
            dataIndex: keyof PurchaseOrderModel,
        ) => {
            confirm();
            // setSearchText(selectedKeys);
            setSearchedColumn(dataIndex);
        };

        const handleReset = (clearFilters: () => void, confirm: FilterDropdownProps['confirm'], dataIndex: keyof PurchaseOrderModel) => {
            clearFilters();
            confirm()
            setSearchText(prev => ({ ...prev, [dataIndex]: '' }))
            // setSearchText([]);
        };
        const getColumnSearchProps = (dataIndex: keyof PurchaseOrderModel, type?: 'date'): ProColumns<PurchaseOrderModel> => ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInput}
                        placeholder={`Tìm kiếm`}
                        value={selectedKeys.length < 2 ? selectedKeys[0] : undefined}
                        onChange={(e) => setSearchText(prev => ({ ...prev, [dataIndex]: e.target.value }))}
                        // onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <hr className="mb-2 mt-1" />

                    {(() => {
                        let arrString = [] as string[];
                        switch (type) {

                            case 'date':
                                const arrDate = dataTable.map(data => data[dataIndex] ? dayjs(data[dataIndex] as string).format('DD/MM/YYYY') : '-') as string[];
                                //const sorted = sortedUniqBy(arrDate, (date) => new Date(date).getTime());
                                // arrString = sorted.map(data => data !== '-' ? dayjs(data).format('DD/MM/YYYY') : data)
                                //Lọc ra những phần tử duy nhất => sau đó chuyển về kiểu mảng
                                const newSet = new Set(arrDate);
                                const sorted = Array.from(newSet);

                                arrString = sorted
                                break;
                            default:
                                arrString = dataTable.map(data => data[dataIndex] ? data[dataIndex] : '-') as any[];
                                break;
                        }
                        // loại bỏ các mã trùng
                        const uniqueArr = uniq(arrString);
                        const searchArr = uniqueArr.filter(i => searchText ? (i + '').toLowerCase().includes(searchText[dataIndex]?.toLowerCase() || '') : true)
                        const checkedAll = searchArr.length === selectedKeys.length
                        const indeterminate = selectedKeys.length > 0 && selectedKeys.length < searchArr.length;
                        const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
                            setSelectedKeys(e.target.checked ? searchArr as string[] : []);
                            // setSearchText(e.target.checked ? uniqueArr as string[] : [])
                        };

                        return (
                            <Space direction='vertical' className="max-h-[250px] overflow-y-auto w-full" >
                                <Checkbox checked={checkedAll}
                                    onChange={onCheckAllChange} indeterminate={indeterminate}>Tất cả</Checkbox>
                                <Checkbox.Group value={selectedKeys as string[]} onChange={(checkValues) => {
                                    setSelectedKeys(checkValues as string[])
                                    // setSearchText(checkValues as string[])
                                }}>
                                    <Space direction='vertical'>
                                        {
                                            searchArr.map((item, index) => (
                                                <Checkbox key={index} value={item}>
                                                    {item}
                                                </Checkbox>
                                            ))
                                        }
                                    </Space>
                                </Checkbox.Group>
                            </Space>
                        )
                    })()}

                    <hr className="mb-2 mt-1" />
                    <Space>
                        <Button
                            type="primary"
                            htmlType="button"
                            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <FaFilter style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            onFilter: (value, record) => {
                if (type === 'date') {
                    return (record[dataIndex] ? dayjs(record[dataIndex] as string).format("DD/MM/YYYY") : '-') === (value as string)
                }
                return (record[dataIndex] || '-')
                    .toString()
                    .toLowerCase() === (value as string).toLowerCase()

            },
            onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
            // render: (text, record) =>
            //     searchedColumn === dataIndex ? (
            //         <Highlighter
            //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            //             searchWords={searchText}
            //             autoEscape
            //             // textToHighlight={text ? text.toString() : ''}
            //             textToHighlight={`${record[dataIndex] ? record[dataIndex] : ''}`}
            //         />
            //     ) : (
            //         text
            //     ),
        });
        // Data search phân trang
        const [dataPaging, setDataPaging] = useState<SearchPO>(initSearch);
        // dữ liệu table
        const [dataTable, setDataTable] = useState<PurchaseOrderModel[]>([]);
        // các row được tick
        const [dataTableSelected, setDataTableSelected] = useState<PurchaseOrderModel[]>([]);
        // key của các row được tick
        const [selectedKeys, setSelectedKeys] = useState<number[]>([])
        const defaultDataTable: ProColumns<PurchaseOrderModel>[] = [
            {
                title: 'STT',
                dataIndex: 'stt',
                key: 'stt',

                width: 50,
                align: 'center',
                readonly: true
            },
            {
                title: 'Ngày giao hàng',
                dataIndex: 'documentDate',
                key: 'documentDate',
                width: 130,
                align: 'center',
                valueType: 'dateTime',

                fieldProps: {
                    format: 'DD/MM/YYYY',
                },
                readonly: true,
                ...getColumnSearchProps('documentDate', 'date')
            },
            {
                title: 'Số PO',
                dataIndex: 'purchaseOrderCode',
                key: 'purchaseOrderCode',

                width: 120,
                align: 'center',
                readonly: true,
                ...getColumnSearchProps('purchaseOrderCode')
            },
            {
                title: 'PO Item',
                dataIndex: 'poItem',
                key: 'poItem',
                width: 70,
                align: 'center',
                readonly: true,
            },
            {
                title: 'Mã hàng hóa',
                dataIndex: 'productCode',
                key: 'productCode',
                width: 120,
                readonly: true,
                align: 'center',
                ...getColumnSearchProps('productCode')
            },
            {
                title: 'Tên hàng hóa',
                dataIndex: 'productName',
                key: 'productName',

                width: 300,
                ellipsis: true,
                readonly: true,
                ...getColumnSearchProps('productName'),
            },
            {
                title: 'Mã nhà cung cấp',
                dataIndex: 'vendorNumber',
                key: 'vendorNumber',
                width: 150,
                align: 'center',
                readonly: true,
                ...getColumnSearchProps('vendorNumber')
            },
            {
                title: 'Nhóm sản phẩm',
                dataIndex: 'productType',
                key: 'productType',
                width: 150,
                readonly: true,
                ...getColumnSearchProps('productType')
            },
            {
                title: 'Tên nhà cung cấp',
                dataIndex: 'vendorName',
                key: 'vendorName',
                width: 350,
                ellipsis: true,
                readonly: true,
                ...getColumnSearchProps('vendorName')
            },
            {
                title: 'Số lượng kế hoạch',
                dataIndex: 'openQuantitySAP',
                key: 'openQuantitySAP',
                width: 160,
                onCell: () => ({
                    className: "text-right"
                }),
                readonly: true,
                render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
            },

            // Số lượng đã đăng ký
            {
                title: 'Số lượng đã đăng ký',
                tooltip: 'Số lượng đã đăng ký = Tổng số lượng hàng giao trong các phiếu đăng ký đã tạo, loại bỏ các phiếu bị hủy và từ chối, không thể truy cập vào cổng.',
                dataIndex: 'deliveryQuantityByKH',
                key: 'deliveryQuantityByKH',
                width: 165,
                onCell: () => ({
                    className: "text-right"
                }),
                render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
            },
            // Số lượng kế hoạch (SAP)


            // Số lượng chưa đăng ký
            {
                title: 'Số lượng chưa đăng ký',
                key: 'slChuaDK',
                tooltip: 'Số lượng chưa đăng ký = số lượng kế hoạch - số lượng đã đăng ký',
                dataIndex: 'slChuaDK',
                width: 170,
                onCell: () => ({
                    className: "text-right"
                }),
                render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
            },
            {
                title: 'Dung sai cho phép của PO',
                dataIndex: 'tolerance',
                valueType() {
                    return {
                        type: 'percent',
                        precision: 3
                    }
                },
                key: 'tolerance',
                width: 170,
                onCell: () => ({
                    className: "text-right"
                }),
                readonly: true,
                // render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
            },
            {
                title: 'Kho',
                dataIndex: 'stockName',
                key: 'stockName',
                width: 150,
                readonly: true,
                ...getColumnSearchProps('stockName')
            },
            {
                title: 'ĐVT',
                dataIndex: 'orderUnit',
                key: 'orderUnit',
                width: 50,
                readonly: true,
            },



            // {
            //     title: 'Loại hàng hóa',
            //     dataIndex: 'productType',
            //     key: 'productType',
            //     width: 150,
            //     readonly: true,
            //     ...getColumnSearchProps('productType')
            // },


            {
                title: 'Số lượng đặt hàng',
                dataIndex: 'totalQuantity',
                key: 'totalQuantity',
                width: 150,
                onCell: () => ({
                    className: "text-right"
                }),
                readonly: true,
                render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
            },
            {
                title: 'Mã nhà máy',
                dataIndex: 'saleOrgCode',
                key: 'saleOrgCode',
                width: 120,
                readonly: true,
                ...getColumnSearchProps('saleOrgCode')
            },
            {
                title: 'Tên nhà máy',
                dataIndex: 'storeName',
                key: 'storeName',
                width: 200,
                readonly: true,
                ...getColumnSearchProps('storeName')
            },
            // Số lượng gộp (Gross weight)
            {
                title: 'Số lượng gộp',
                dataIndex: 'grossWeight',
                key: 'grossWeight',
                width: 100,
                onCell: () => ({
                    className: "text-right"
                }),
                readonly: true,
                render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
            },
            {
                title: 'Số lượng đã nhập kho',
                dataIndex: 'receivedQuantity',
                key: 'receivedQuantity',
                width: 150,
                onCell: () => ({
                    className: "text-right"
                }),
                readonly: true,
                render: (text) => text !== '-' ? formatNumber(Number(text), 3) : text
            },
            {
                title: 'Số PR',
                dataIndex: 'prNumber',
                key: 'prNumber',

                width: 120,
                readonly: true,
                align: 'center',
                ...getColumnSearchProps('prNumber')
            },
            {
                title: 'Thời gian đồng bộ',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 150,
                align: 'center',
                valueType: 'dateTime',
                fieldProps: {
                    format: 'DD/MM/YYYY HH:mm:ss',
                },
                readonly: true,
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: 400,
                render: (text, record) => {
                    return (
                        <div className="flex justify-between items-center">
                            <p>{text}</p>
                            {isUpdateNote && (
                                <div className="w-12 pl-6">
                                    <Tooltip title="Thêm ghi chú">
                                        <span onClick={() => {
                                            setOpenModalUpdate(true);
                                            formUpdate.setFieldsValue({
                                                purchaseOrderDetailId: record.purchaseOrderDetailId,
                                                note: record.note
                                            })
                                        }}>
                                            <MdOutlineNoteAlt className="text-lg hover:text-blue-500" />
                                        </span>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )
                }
            },

            // Dung sai cho phép của PO

            {
                title: 'Loại PO',
                dataIndex: 'documentType',
                key: 'documentType',
                width: 100,
                readonly: true,
                ...getColumnSearchProps('documentType')
            },




            // trạng thái
            {
                title: "Trạng thái",
                dataIndex: 'actived',
                width: 80,
                key: 'actived',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div>
                            {record.actived ? (
                                <Icon.CheckStatus className="w-4 text-sm text-center mx-auto !fill-[#389E0D]" />
                            ) : (
                                <Icon.CloseStatus className="w-4 text-sm text-center mx-auto !fill-[#D93133E5]" />
                            )}
                        </div>
                    );
                },
            },
            {
                title: "Chức năng",
                key: 'functions',
                width: 150,
                hideInTable: !isUpdatePO && !isCreatePO && !isDeletePO ? true : false,
                hideInSetting: !isUpdatePO && !isCreatePO && !isDeletePO ? true : false,
                align: 'center',
                render: (_, record) => {
                    return (
                        <div className="w-full flex items-center justify-center gap-x-1">
                            {isUpdatePO && record.isCreateBySystem && (
                                <Tooltip title={"Chỉnh sửa"}>
                                    <Link to={ROUTES.PURCHASE_ORDER.EDIT_PO.LINK + record.purchaseOrderDetailId}>
                                        <CustomButtonIcon color="#007BF1">
                                            <PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                                        </CustomButtonIcon>
                                    </Link>
                                </Tooltip>
                            )}
                            {isCreatePO && record.actived && (
                                <Tooltip title={'Sao chép'}>
                                    <CustomButtonIcon
                                        color="#FF69B4"
                                        onClick={() => navigate(`${ROUTES.PURCHASE_ORDER.COPY_PO.LINK}/${record.purchaseOrderDetailId}`)}
                                    // className={`${record.actived ? '!block' : '!invisible'} !leading-[25px] !h-[25px]`}
                                    >
                                        <CopyOutlined className="!text-[#FF69B4] flex" />
                                    </CustomButtonIcon>
                                </Tooltip>
                            )}
                            {isDeletePO && record.isCreateBySystem && record.actived && (
                                <Tooltip title={'Xóa'}>
                                    <CustomButtonIcon
                                        color="#FC5C5E"
                                        onClick={() => {
                                            showDeleteConfirm(record)
                                        }}
                                    // className={`${record.actived ? '!block' : '!invisible'} !leading-[25px] !h-[25px]`}
                                    >
                                        <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                                    </CustomButtonIcon>
                                </Tooltip>
                            )}
                        </div>
                    );
                },
            },
        ]

        const [columns, setColumns] = useState<ProColumns<PurchaseOrderModel>[]>(defaultDataTable)
        // Call api lấy dữ liệu table
        const getDataTable = async (dataSearch: SearchModel) => {
            setLoadingTable(true)
            await purchaseOrderPresenter.getListPO(purchaseOrderStore, { ...dataSearch, paging: { ...dataSearch.paging, pageSize: 1000000000 } })
            setLoadingTable(false)
            if (purchaseOrderStore.purchseOrderRes?.isSuccess) {
                const dataClone = purchaseOrderStore.purchseOrderRes?.data
                setDataTable(dataClone)
            }
        }
        //xử lý bấm nút tạo phiếu
        const handleCreateVote = () => {
            if (selectedKeys.length) {
                const select = map(dataTableSelected, 'purchaseOrderDetailId')
                const dataFilter = cloneDeep(purchaseOrderStore.purchseOrderRes?.data)
                const fil = dataFilter.filter(data => select.includes(data.purchaseOrderDetailId))
                // kiểm tra có cùng nhà cung cấp hay không
                let checkVendor = false
                for (let i = 0; i < fil.length; i++) {
                    if (fil.length === 1) {
                        break;
                    }
                    if (fil.at(i)?.vendorNumber !== fil.at(i - 1)?.vendorNumber) {
                        checkVendor = true
                        break;
                    }
                }
                // AVNMR-79
                // kiểm tra xem có cùng loại hàng hóa không
                //sử dụng _.map để trích xuất tất cả các giá trị trong trường "goodsType" và sau đó sử dụng _.uniq để lấy ra loại dữ liệu duy nhất.
                // Nếu chiều dài của mảng kết quả là 1, có nghĩa là tất cả các giá trị trong trường "goodsType" có cùng loại dữ liệu,
                // và hàm sẽ trả về true. Ngược lại, nó sẽ trả về false.
                const checkGoodsType = uniq(map(dataTableSelected, 'goodsType'))
                const checkDocumentDate = uniq(map(dataTableSelected, 'documentDate'))
                const checkSlChuaDK = dataTableSelected.some(check => (check.slChuaDK || 0) > 0)
                // cùng nhà cung cấp
                if (checkVendor) {
                    message.error("Các nhà cung cấp được chọn phải giống nhau.")
                }
                // cùng loại hàng hóa
                else if (checkGoodsType.length !== 1) {
                    message.error("Các PO được chọn không cùng loại hàng hóa.")
                }
                // cùng ngày giao hàng
                else if (checkDocumentDate.length !== 1) {
                    message.error("Các PO được chọn không cùng ngày giao hàng.")
                }
                // số lượng chưa đăng ký phải lớn hơn 0
                else if (!checkSlChuaDK) {
                    message.error("Số lượng đăng ký của tất cả các PO được chọn phải lớn hơn 0.")
                }
                else {
                    navigate(ROUTES.PURCHASE_ORDER.LIST_PO.CREATE_VOTE.LINK, { state: fil })
                }
            } else {
                message.warning("Vui lòng chọn PO!")
            }
        }


        const showDeleteConfirm = (item: PurchaseOrderModel) => {
            Modal.confirm({
                title: `Xóa kế hoạch giao hàng "${item.purchaseOrderCode}"`,
                icon: <ExclamationCircleFilled />,
                content: `Bạn có chắc chắn muốn xóa kế hoạch này không ?`,
                okText: "Xóa",
                okType: 'danger',
                cancelText: "Hủy",
                className: 'custom__modalconfirm--delete',
                async onOk() {
                    const response = await purchaseOrderPresenter.deletePurchaseOrder(item.purchaseOrderDetailId)
                    if (response.isSuccess) {
                        message.success(response.message)
                        getDataTable(dataPaging)
                    } else {
                        message.error(response.message)
                    }
                },
                onCancel() { },
            });
        };

        // set lại colum khi data table thay đổi 
        // cập nhật lại filter
        useEffect(() => {
            setColumns(prev => {
                const col = defaultDataTable.map((column, index) => ({ ...column, width: prev[index].width }))
                return col
            })
        }, [dataTable, searchText]);

        useEffect(() => {
            if (notificationStore.createDate) {
                searchForm.setFieldsValue({
                    purchaseOrderCodes: notificationStore.listPOAsynch,
                    createTimeFrom: notificationStore.createDate && dayjs(notificationStore.createDate),
                    createTimeTo: notificationStore.createDate && dayjs(notificationStore.createDate),
                    common1: 'Custom'
                })
                const search = {
                    ...dataPaging,
                    purchaseOrderCodes: notificationStore.listPOAsynch,
                    createTimeFrom: notificationStore.createDate && dayjs(notificationStore.createDate).format("YYYY-MM-DD"),
                    createTimeTo: notificationStore.createDate && dayjs(notificationStore.createDate).format("YYYY-MM-DD")
                }
                setDataPaging(search)
                getDataTable(search)
            }
        }, [notificationStore.createDate, notificationStore.listPOAsynch]);

        useEffect(() => {
            getDataTable(dataPaging)

            return () => {
                notificationPresenter.setCreateDate(notificationStore, undefined)
                notificationPresenter.setListPOAsynch(notificationStore, [])
            }
        }, []);


        // == CẤU HÌNH TABLE =================================================================
        const constDataName = 'DanhSachPO';
        const {
            isResizablePresent,
            setIsResizablePresent,
            resizableColumnSelect,
            columnsState,
            handleColumnsStateChange,
            templateReportModalRef,
            userReports,
            selectedReportId,
            templateReportModalProps,
            handleResize,
        } = useResizableColumns<PurchaseOrderModel>(columns, setColumns, constDataName);
        return (
            <div className="no-hover-effect">
                <PageWrapper breadcrumb={[{
                    text: "Kế hoạch giao hàng"
                }]}
                    extras={
                        <>
                            {isCreateVote && <CustomButton icon={<MdOutlinePostAdd className="text-base" />} type="default" disabled={!dataTableSelected.length} onClick={() => handleCreateVote()}>Tạo phiếu</CustomButton>}
                            {isCreatePO && <Link to={ROUTES.PURCHASE_ORDER.ADD_PO.LINK}>
                                <CustomButton type="primary" icon={<FaPlus />} >Thêm mới</CustomButton>
                            </Link>}
                            {isExport && <ExportButton loading={isLoading} onClick={async () => {
                                setIsLoading(true);
                                const exportRequest = cloneDeep(dataPaging)
                                await purchaseOrderPresenter.exportListPO(omit(exportRequest, ['paging']))
                                setIsLoading(false);
                            }} />}
                            {isExportTemplate && <ExportButton
                                color="#f59e0b"
                                title='Export template'
                                loading={loadingExport}
                                onClick={async () => {
                                    setLoadingExport(true)
                                    await purchaseOrderPresenter.exportCreatePOTemplate()
                                    setLoadingExport(false)
                                }}
                            />}
                            {isImport && <Upload
                                maxCount={1}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                showUploadList={false}
                                onChange={async (info) => {
                                    if (info.file.status === "uploading") {
                                        setLoadingImport(true)
                                    }
                                    if (info.file.status === 'done') {
                                        setLoadingImport(false)
                                        message.success(info.file.response.message);
                                        setDataPaging(initSearch)
                                        searchForm.setFieldsValue({
                                            ...initSearch,
                                            documentDateFrom: dayjs(),
                                            documentDateTo: dayjs()
                                        })
                                        getDataTable(initSearch)
                                    }
                                    if (info.file.status === 'error') {
                                        message.error(info.file.response.message);
                                        setLoadingImport(false)
                                    }
                                }}
                                action={BASE_API_URL + '/purchaseorder/import-po'}
                                headers={{
                                    Authorization: `Bearer ${getAccessToken()}`,
                                }}
                                name="File"
                            >
                                <CustomButton
                                    type="primary"
                                    color="#3b82f6"
                                    loading={loadingImport}
                                    icon={<Icon.Upload className="!fill-white" />}>
                                    Import
                                </CustomButton>
                            </Upload>
                            }

                            <CustomButton
                                onClick={() => setOpenDrawer(true)}
                                type="primary" color="#EFF4F9"
                                className="!px-0 w-8"
                            >
                                <Filter className="!fill-primary" />
                            </CustomButton>
                        </>
                    }
                >
                    {/* Table */}
                    <BoxContainer >
                        <div className='m-0 p-0'>
                            <div className="flex flex-row">
                                <div className='w-4 h-4 bg-white border-[0.2px] mx-2' />
                                <span className='text-sm'>KH chưa tạo phiếu</span>
                            </div>
                            <div className="flex flex-row">

                                <div className='w-4 h-4 bg-[#B4E2B9] border-[0.2px] mx-2' />
                                <span className='text-sm'>KH đã và đang được tạo phiếu</span>
                            </div>
                            <div className="flex flex-row">

                                <div className='w-4 h-4 bg-[#38B244] border-[0.2px] mx-2' />
                                <span className='text-sm'>KH đã tạo đủ số lượng</span>
                            </div>
                            <div className="flex flex-row">

                                <div className='w-4 h-4 bg-[#dc2626] border-[0.2px] mx-2' />
                                <span className='text-sm'>KH tạo tay</span>
                            </div>
                        </div>
                        <CustomProTable<PurchaseOrderModel>
                            bordered
                            //rowClassName={rowClassName}
                            columns={columns}
                            dataSource={dataTable}
                            loading={loadingTable}
                            rowSelection={{
                                onChange(selectedRowKeys, selectedRows, info) {
                                    setSelectedKeys(selectedRowKeys as number[])
                                    if (info.type === 'all' && selectedRows.length !== 0) {
                                        const dataClone = cloneDeep(dataTableSelected)
                                        const selectedRowsClone = cloneDeep(selectedRows)
                                        dataClone.push(...selectedRowsClone)
                                        // gộp mảng dataClone và temp và loại bỏ các item trùng purchaseOrderDetailId
                                        const uniqueArray = uniqBy(dataClone, 'purchaseOrderDetailId')
                                        setDataTableSelected(uniqueArray || [])
                                    } else if (info.type === 'all' && selectedRows.length === 0) {
                                        const dataClone = cloneDeep(dataTableSelected)
                                        const temp = cloneDeep(dataTable)
                                        // bỏ các item trong mảng dataClone có purchaseOrderDetailId bên temp (lấy phần khác)
                                        const filter = differenceWith(dataClone, temp, (itemA, itemB) => itemA.purchaseOrderDetailId === itemB.purchaseOrderDetailId)
                                        setDataTableSelected(filter)
                                    }
                                },
                                onSelect(record, selected) {
                                    if (selected) {
                                        const dataClone = cloneDeep(dataTableSelected)
                                        dataClone.push(record)
                                        setDataTableSelected(dataClone)
                                    } else {
                                        const dataClone = cloneDeep(dataTableSelected)
                                        const dataFilter = dataClone.filter(row => row.purchaseOrderDetailId !== record.purchaseOrderDetailId)
                                        setDataTableSelected(dataFilter)
                                    }
                                },
                                // columnTitle: "Chức năng",
                                columnWidth: 60,
                                fixed: 'left',
                                selections: [
                                    {
                                        key: "all",
                                        text: 'Tất cả',
                                        onSelect: () => {
                                            setDataTable(purchaseOrderStore.purchseOrderRes?.data)
                                        }
                                    },
                                    {
                                        key: "selected",
                                        text: 'Đã chọn',
                                        onSelect: () => {
                                            const select = map(dataTableSelected, 'purchaseOrderDetailId')
                                            const dataFilter = cloneDeep(purchaseOrderStore.purchseOrderRes?.data)
                                            const fil = dataFilter.filter(data => select.includes(data.purchaseOrderDetailId))
                                            setDataTable(fil)
                                        }
                                    },
                                    {
                                        key: "unselecte",
                                        text: 'Chưa chọn',
                                        onSelect: () => {
                                            const select = map(dataTableSelected, 'purchaseOrderDetailId')
                                            const dataFilter = cloneDeep(purchaseOrderStore.purchseOrderRes?.data)
                                            const fil = dataFilter.filter(data => !select.includes(data.purchaseOrderDetailId))
                                            setDataTable(fil)
                                        }
                                    },
                                ],
                                selectedRowKeys: map(dataTableSelected, 'purchaseOrderDetailId'),
                                getCheckboxProps(record) {

                                    // AVNMR-50 
                                    // Điều kiện: Nếu số lượng còn lại theo kế hoạch > 0 -> mới cho tạo phiếu. Nếu < 0 -> cảnh báo "Không thể tạo phiếu khi số lượng còn lại là 0". 
                                    // cùng nhà cung cấp và cùng ngày giao hàng mới được tạo phiếu
                                    return {

                                        disabled: (() => {
                                            if (dataTableSelected.length) {
                                                return !dataTableSelected.some((data) =>
                                                    // cùng nhà cung cấp
                                                    (data.vendorNumber === record.vendorNumber) &&
                                                    // Số lượng chưa đăng ký lớn hơn 0
                                                    ((record.slChuaDK || 0) > 0) &&
                                                    // cùng ngày giao hàng
                                                    (record?.documentDate === data?.documentDate) &&
                                                    (record?.goodsType === data?.goodsType)
                                                ) || !record.actived
                                            }
                                            else {
                                                return ((record.slChuaDK || 0) <= 0) || !record.actived
                                            }
                                        })(),
                                    }
                                },
                            }}
                            onRow={(record, index) => {
                                const deliveryQuantityByKH = record.deliveryQuantityByKH || 0;
                                const openQuantitySAP = record.openQuantitySAP ?? 0; // Default to 0 if undefined
                                let backgroundColor = ''; // Default background color
                                let color = ''; // Default text color

                                if (deliveryQuantityByKH > 0 && deliveryQuantityByKH < openQuantitySAP) {
                                    backgroundColor = '#B4E2B9';
                                } else if (deliveryQuantityByKH === null || deliveryQuantityByKH === 0) {
                                    backgroundColor = '#fff';
                                } else if (record.slChuaDK === 0 || record.slChuaDK === null) {
                                    backgroundColor = '#38B244';
                                }

                                // Nếu isCreatedBySystem là true, đổi màu chữ thành màu đỏ
                                if (record.isCreateBySystem) {
                                    color = '#dc2626';
                                }

                                return {
                                    style: {
                                        background: backgroundColor,
                                        color: color,
                                    },
                                };
                            }}
                            key={tableKey}
                            tableAlertRender={false}
                            handleResize={handleResize}
                            isResizablePresent={isResizablePresent}
                            resizableColumnSelect={resizableColumnSelect}
                            columnsState={{
                                value: Object.keys(columnsState).length > 0 ? columnsState : undefined,
                                onChange: (newColumnsState) => {
                                    handleColumnsStateChange(newColumnsState);
                                },
                            }}
                            toolBarRender={() => [
                                // nút lưu mẫu bc
                                <SaveReportForm
                                    setIsResizablePresent={setIsResizablePresent}
                                    templateReportModalRef={templateReportModalRef}
                                    userReports={userReports}
                                    selectedReportId={selectedReportId}
                                    templateReportModalProps={templateReportModalProps}
                                />,
                            ]}
                            rowKey="purchaseOrderDetailId"
                            pagination={{
                                ...tablePagination(dataPaging, setDataPaging),
                                // total: purchaseOrderStore.purchseOrderRes?.paging?.recordsTotal,
                            }}
                        />
                    </BoxContainer>
                    <SearchBox
                        onClose={() => { setOpenDrawer(false) }}
                        className="lg:!w-[50%]"
                        open={openDrawer}
                        footer={
                            <CustomButton
                                htmlType="submit"
                                className="my-auto ml-auto"
                                type="primary"
                                loading={isLoading}
                                icon={<HiSearch />}
                                onClick={() => {
                                    searchForm.submit();
                                }}
                            >
                                Tìm kiếm
                            </CustomButton>
                        }
                    >
                        <SearchForm
                            form={searchForm}
                            className="gap-x-16 !pr-5"
                            onFinish={(values) => {
                                const valueClone = cloneDeep(values)
                                // convert lại from date to date
                                const documentDateFrom = valueClone?.documentDateFrom ? valueClone.documentDateFrom?.format("YYYY-MM-DD") : undefined
                                const documentDateTo = valueClone?.documentDateTo ? valueClone.documentDateTo?.format("YYYY-MM-DD") : undefined
                                const createTimeFrom = valueClone?.createTimeFrom ? valueClone.createTimeFrom?.format("YYYY-MM-DD") : undefined
                                const createTimeTo = valueClone?.createTimeTo ? valueClone.createTimeTo?.format("YYYY-MM-DD") : undefined
                                Object.assign(valueClone, { documentDateFrom, documentDateTo, createTimeFrom, createTimeTo })
                                // bỏ các giá trị rỗng null và trim giá trị
                                removeEmptyValues(valueClone)
                                const dataSearch: SearchPO = { paging: { ...dataPaging.paging, pageIndex: 1 }, ...valueClone }
                                setDataPaging(dataSearch)
                                setOpenDrawer(false);
                                setDataTable([])
                                setTableKey(prev => prev + 1)
                                setSearchText({})
                                getDataTable(dataSearch)
                            }}
                            colon={false}
                        >
                            <SearchPOForm

                                searchForm={searchForm}
                                setIsLoading={setIsLoading}
                            />
                        </SearchForm>
                    </SearchBox>

                    <Modal
                        open={openModalUpdate}
                        onCancel={() => {
                            setOpenModalUpdate(false);
                        }}
                        footer={false}
                        title={<h3 className="text-base font-bold uppercase">Cập nhật PO item</h3>}
                    >
                        <hr className="mb-3 -mt-1"></hr>
                        <SearchForm
                            form={formUpdate}
                            column="col-1"
                            onFinish={async (values) => {
                                setOpenModalUpdate(false)
                                const result = await purchaseOrderPresenter.updatePOItem(values)
                                if (result) {
                                    getDataTable(dataPaging)
                                }
                            }}
                        >
                            <SearchItem name={'purchaseOrderDetailId'} hidden>
                                <Input />
                            </SearchItem>
                            <SearchItem name={'note'} label="Ghi chú">
                                <Input.TextArea
                                    placeholder="Nhập ghi chú..."
                                    rows={3} />
                            </SearchItem>
                            <div className="mt-3 -mb-3 flex gap-default justify-end">
                                <CustomButton
                                    onClick={() => setOpenModalUpdate(false)}
                                    htmlType="button">
                                    Hủy
                                </CustomButton>
                                <CustomButton type="primary" htmlType="submit">
                                    Lưu
                                </CustomButton>
                            </div>
                        </SearchForm>
                    </Modal>
                </PageWrapper>
            </div>

        )
    })
}

